import { NavBar } from '../components/NavBar'
import { Footer } from '../components/Footer'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import client from '../client.js'
import imageUrlBuilder from '@sanity/image-url'
import { Hero2022 } from '../sections/homeHero/2022'
import { Hero2023 } from '../sections/homeHero/2023'
import { Hero2024 } from '../sections/homeHero/2024'
import { Hero2025 } from '../sections/homeHero/2025'

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

export const Home = ({ year = 2024 }) => {
  const [allPostsData, setAllPosts] = useState(null)

  useEffect(() => {
    client
      .fetch(
        `*[_type == "story" && year == "${year}"] | order(order asc){
          title,
          slug,
          mainImage,
          "mainImageHotSpot": mainImage.hotspot,
          cardImage,
          firstByline,
          iconColor,
          seoDescription,
          timeToRead,
        }
      `
      )
      .then((data) => {
        setAllPosts(data)
      })
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        class={`fixed top-0 left-0 right-0 z-[9999] h-screen w-screen bg-gray-50
        ${allPostsData ? 'pointer-events-none opacity-0' : 'opacity-100'}
        transition-opacity duration-500 ease-in-out
        `}
      >
        <div class="flex h-full w-full items-center justify-center">
          <div class="h-20 w-20 animate-spin rounded-full border-4 border-t-sv-yellow border-r-sv-green border-l-sv-light-blue border-b-sv-crimson"></div>
        </div>
      </div>
      <NavBar year={year} />
      <div className="mx-auto">
        {year === 2022 && <Hero2022 />}
        {year === 2023 && <Hero2023 />}
        {year === 2024 && <Hero2024 />}
        {year === 2025 && <Hero2025 />}

        <div class="relative z-10 bg-white px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
          <div class="relative mx-auto -mt-48 max-w-7xl lg:-mt-72">
            <div class="mx-auto mt-12 grid max-w-[26rem] gap-8 sm:max-w-[54rem] sm:grid-cols-2 lg:max-w-none lg:grid-cols-3">
              {allPostsData &&
                allPostsData.map((post, index) => (
                  <Link
                    to={post?.slug?.current}
                    className={`group flex transform flex-col overflow-hidden rounded-3xl border-none shadow-sm outline-none transition-all duration-500 visited:opacity-50 hover:-translate-y-1 hover:rounded-none hover:opacity-100 hover:shadow-2xl focus:-translate-y-1 focus:rounded-none focus:shadow-2xl motion-reduce:transition-none [&:nth-child(6n+1)]:hover:shadow-sv-navy/40 [&:nth-child(6n+2)]:hover:shadow-sv-green/50 [&:nth-child(6n+3)]:hover:shadow-sv-yellow/70 [&:nth-child(6n+4)]:hover:shadow-sv-orange/50 [&:nth-child(6n+5)]:hover:shadow-sv-light-blue/50 [&:nth-child(6n)]:hover:shadow-sv-crimson/40`}
                    style={{
                      filter: 'blur(0px)',
                    }}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div class="relative flex-shrink-0 overflow-hidden bg-black">
                      {post?.mainImage ? (
                        <img
                          class="h-72 w-full transform object-cover opacity-100 filter transition-all duration-500 group-hover:-translate-y-12 group-hover:scale-105 group-hover:grayscale-0 group-focus:-translate-y-12 group-focus:scale-105 group-focus:grayscale-0 md:h-96"
                          src={
                            post?.cardImage
                              ? urlFor(post?.cardImage)?.width(800).url()
                              : urlFor(post?.mainImage)?.width(800).url()
                          }
                          alt=""
                          style={{
                            objectPosition: `${post?.mainImageHotSpot?.x * 100}% ${post?.mainImageHotSpot?.y * 100}%`,
                          }}
                        />
                      ) : (
                        <div class="h-72 w-full transform object-cover opacity-100 filter transition-all duration-500 group-hover:-translate-y-12 group-hover:scale-105 group-hover:grayscale-0 group-focus:-translate-y-12 group-focus:scale-105 group-focus:grayscale-0 md:h-96"></div>
                      )}
                      <div class="absolute left-0 right-0 bottom-0 h-full bg-gradient-to-t from-black to-transparent opacity-70 transition-all duration-500 group-hover:h-full group-hover:opacity-100"></div>
                    </div>
                    <div class="pointer-events-none absolute bottom-0 w-full translate-y-[70px] transform transition-all duration-500 group-hover:translate-y-0 group-focus:translate-y-0">
                      <div class="flex flex-1 flex-col justify-between bg-transparent p-6">
                        <div class="flex-1">
                          <div class="mt-2 block">
                            <p class="text-xl font-semibold text-white">
                              {post?.title}
                            </p>
                            <p class="mt-3 text-base text-gray-100 line-clamp-3">
                              {post?.seoDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center bg-gray-50 p-6">
                        <div class="flex w-full justify-between">
                          <p class="truncate text-sm font-medium text-gray-900">
                            <span class="hover:underline ">
                              {post.firstByline}
                            </span>
                          </p>
                          {post?.timeToRead && (
                            <div class="flex flex-shrink-0 space-x-1 whitespace-nowrap text-sm text-gray-500">
                              <span>{post.timeToRead} min read </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
