import { getAllEpisodes } from '../../lib/episodes'
import { EpisodePlayButton } from '../../audio-components/EpisodePlayButton'
import { AudioPlayer } from '../../audio-components/player/AudioPlayer'
import { AudioProvider } from '../../audio-components/AudioProvider'
import { useState } from 'react'

function PauseIcon(props) {
  return (
    <svg aria-hidden="true" viewBox="0 0 10 10" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.496 0a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5H2.68a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5H1.496Zm5.82 0a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5H8.5a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5H7.316Z"
      />
    </svg>
  )
}

function PlayIcon(props) {
  return (
    <svg aria-hidden="true" viewBox="0 0 10 10" {...props}>
      <path d="M8.25 4.567a.5.5 0 0 1 0 .866l-7.5 4.33A.5.5 0 0 1 0 9.33V.67A.5.5 0 0 1 .75.237l7.5 4.33Z" />
    </svg>
  )
}

function EpisodeEntry({ episode }) {
  return (
    <article aria-labelledby={`song-${episode.id}-title`} className="py-6">
      <div className="flex flex-col items-start">
        <h2
          id={`song-${episode.id}-title`}
          className="mt-2 text-lg font-bold text-gray-900"
        >
          {episode.title}
        </h2>
        <p className="mt-1 text-base leading-7 text-gray-700">
          {episode.description}
        </p>
        <div className="mt-4 flex items-center gap-4">
          <EpisodePlayButton
            episode={episode}
            className="flex items-center gap-x-3 text-sm font-bold leading-6 text-sv-crimson/80 hover:text-sv-crimson/70 active:text-sv-crimson/100"
            playing={
              <>
                <PauseIcon className="h-2.5 w-2.5 fill-current" />
                <span aria-hidden="true">Listen</span>
              </>
            }
            paused={
              <>
                <PlayIcon className="h-2.5 w-2.5 fill-current" />
                <span aria-hidden="true">Listen</span>
              </>
            }
          />
        </div>
      </div>
    </article>
  )
}

export const StudentMusic = () => {
  let items = [
    {
      id: 1,
      title: 'Lovely Design (Violet Warren)',
      published: 1645660800000,
      description: '12th Grade, Twin Peaks Classical Academy',
      enclosures: [
        {
          url: 'https://dev-st-vrain-valley-schools.pantheonsite.io/wp-content/uploads/2025/01/Lovely-Design-VW.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 2,
      title: 'Untitled (Luis Macías)',
      published: 1645660800000,
      description: '2024 Graduate, Longmont High School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Untitled-LM.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 3,
      title: 'Dysfunctional (Daisy ‘Ollie’ O’Brien)',
      published: 1645660800000,
      description: '11th Grade, Niwot High School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Dysfunctional-DO.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 4,
      title: 'Undermined (Shiloh Warren)',
      published: 1645660800000,
      description: '12th Grade, Twin Peaks Classical Academy',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Undermined-SW.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 5,
      title: 'Constant (Samara McDermid)',
      published: 1645660800000,
      description: '10th Grade, Niwot High School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Constant-SM.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 6,
      title: 'Untitled (Eric Brunsman)',
      published: 1645660800000,
      description: '10th Grade, Frederick High School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Untitled-EB.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 7,
      title: 'Apollo (Izak ‘Hazel’ Blue)',
      published: 1645660800000,
      description: '8th Grade, Homeschool',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Apollo-IB1.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 8,
      title:
        'Self Love Vacation (Elizabeth ‘Ellie’ Eckstine and Olive ‘Ollie’ McElroy)',
      published: 1645660800000,
      description: 'Ellie: 9th Grade, APEX, Ollie: 8th Grade, Flagstaff',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Self-Love-Vacation-Audio-Only.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 9,
      title: 'Untitled (Matthew Schwartz)',
      published: 1645660800000,
      description: '8th Grade, Altona Middle School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Untitled-MS.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 10,
      title: 'Through the Deep (Charlotte Castaneda)',
      published: 1645660800000,
      description: '9th Grade, Niwot High School',
      enclosures: [
        {
          url: 'test',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 10,
      title: 'Untitled (Ashby Goodwin)',
      published: 1645660800000,
      description: '7th Grade, Stargate School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Untitled-AG.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 11,
      title: 'Drone Show Render (Emily Whiteley and Tyler Detro)',
      published: 1645660800000,
      description:
        'Emily Whiteley: 11th Grade, Erie High School, Tyler Detro: 12th Grade, Erie High School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Butterfly-Drone-Show.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 12,
      title: 'He is Watching (Jack O’Toole)',
      published: 1645660800000,
      description: '8th Grade, Altona Middle School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/He-is-Watching-Jack-O_Toole.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 13,
      title:
        'Pong Theme Song (Ivan Chang, Arnav Thakar, Cary Peterson, Evrim Birsoy)',
      published: 1645660800000,
      description:
        'Ivan Chang: 8th Grade, Altona Middle School, Arnav Thakar: 7th Grade, Altona Middle School, Cary Peterson: 7th Grade, Flagstaff Middle School, Evrim Birsoy: 7th Grade, Flagstaff Middle School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Pong-Theme-Song.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 14,
      title: 'Untitled (Forest Hightower)',
      published: 1645660800000,
      description: '5th Grade, Centennial Elementary School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Untitled-FH.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 15,
      title: 'Untitled (Emma Marcenco)',
      published: 1645660800000,
      description: '4th Grade, Soaring Heights Elementary School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Untitled-EM.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 16,
      title: 'Untitled (Malia Martinez)',
      published: 1645660800000,
      description: '5th Grade, Mead Elementary School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Untitled-MM.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 17,
      title: 'Untitled (Elijah Margheim)',
      published: 1645660800000,
      description: '5th Grade, Firestone Charter',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Untitled-ElM-.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
    {
      id: 18,
      title: 'Untitled (Maxwell Zaun)',
      published: 1645660800000,
      description: '4th Grade, Red Hawk Elementary School',
      enclosures: [
        {
          url: 'https://www.svvsd.org/wp-content/uploads/2025/01/Untitled-MZ.mp3',
          type: 'audio/mpeg',
        },
      ],
    },
  ]
  let episodes = items.map(
    ({ id, title, description, content, enclosures, published }) => ({
      id,
      title: title,
      published: new Date(published),
      description,
      content,
      audio: enclosures.map((enclosure) => ({
        src: enclosure.url,
        type: enclosure.type,
      }))[0],
    })
  )
  const [visibleEpisodes, setVisibleEpisodes] = useState(5) // Show first 5 episodes initially

  const showMoreEpisodes = () => {
    setVisibleEpisodes((prev) => prev + 5) // Show 5 more episodes when clicked
  }

  return (
    <AudioProvider>
      <div className="relative">
        <div className="divide-y-2">
          {episodes.slice(0, visibleEpisodes).map((episode) => (
            <EpisodeEntry key={episode.id} episode={episode} />
          ))}
        </div>

        {visibleEpisodes < episodes.length && (
          <div className="absolute inset-x-0 bottom-0 flex h-48 items-end justify-center">
            <div className="absolute -inset-x-12 bottom-0 flex h-48 items-end justify-center bg-white/70 backdrop-blur-sm [mask:linear-gradient(180deg,transparent_0%,black_80%)]"></div>
            <button
              onClick={showMoreEpisodes}
              className="relative rounded-full bg-sv-crimson py-2 px-4 text-sm text-white transition-colors hover:opacity-90"
            >
              Show More Music
            </button>
          </div>
        )}
      </div>
      <div className="lg:left-112 xl:left-120 fixed inset-x-0 bottom-0 z-10">
        <AudioPlayer />
      </div>
    </AudioProvider>
  )
}
