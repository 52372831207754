import { Link } from 'react-router-dom'

export const NavBar = ({ year }) => {
  return (
    <header class="top-0 z-50 w-full bg-white shadow-md md:sticky">
      <nav class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div class="flex w-full items-center justify-between py-6 lg:border-none">
          <Link
            to={year ? `/${year}` : '/'}
            className="navbar-logo relative mx-auto shrink-0 rounded-full bg-[#27A1B9] pr-4 font-condensed text-2xl font-light uppercase tracking-[0.3em] text-white lg:mx-0"
            onClick={() => window.scrollTo(0, 0)}
            aria-label="St. Vrainnnovation - Return to Home"
          >
            <div
              className="pointer-events-none absolute left-[5.6em] h-full w-auto select-none rounded-full bg-[color:rgb(50,43,41)] pl-1 text-center"
              aria-hidden="true"
            >
              IN
            </div>
            <span
              className="inline-block rounded-l-full bg-sv-crimson pl-4"
              aria-hidden="true"
            >
              St. Vrai
            </span>
            <span aria-hidden="true">nnovation</span>
          </Link>
          <div class="flex items-center">
            <div class="invisible w-0 flex-wrap items-center justify-end gap-x-8 gap-y-4 overflow-hidden lg:visible lg:ml-10 lg:flex lg:w-auto">
              <Link
                to={year ? `/${year}/about` : '/about'}
                // scroll to top of page
                onClick={() => window.scrollTo(0, 0)}
                class="whitespace-nowrap text-base font-medium text-gray-700 hover:text-gray-500"
              >
                About
              </Link>

              <a
                href="https://issuu.com/svvsd/docs/2024-stvrainnovation-magazine"
                target="_blank"
                rel="noreferrer noopener"
                class="whitespace-nowrap text-base font-medium text-gray-700 hover:text-gray-500"
              >
                Download
              </a>

              <Link
                to="/archive"
                class="whitespace-nowrap text-base font-medium text-gray-700 hover:text-gray-500"
              >
                Past Issues
              </Link>
              <Link
                to={year ? `/${year}/print` : '/print'}
                // scroll to top of page
                onClick={() => window.scrollTo(0, 0)}
                class="whitespace-nowrap text-base font-medium text-gray-700 hover:text-gray-500"
              >
                Request a Printed Copy
              </Link>
              {year !== 2024 && (
                <Link
                  to="/2024"
                  class="whitespace-nowrap rounded-full bg-sv-crimson px-4 py-2 text-base font-medium text-white hover:bg-sv-crimson/80"
                  reloadDocument
                >
                  Current Issue
                </Link>
              )}
            </div>
          </div>
        </div>
        <div class="flex flex-wrap justify-center space-x-6 py-4 lg:hidden">
          <Link
            to={year ? `/${year}/about` : '/about'}
            class="text-base font-medium text-gray-700 hover:text-gray-500"
          >
            About
          </Link>
          <a
            href="https://issuu.com/svvsd/docs/stvrainnovationmagazine_2023"
            target="_blank"
            rel="noreferrer noopener"
            class="text-base font-medium text-gray-700 hover:text-gray-500"
          >
            Download
          </a>

          <Link
            to="/archive"
            class="text-base font-medium text-gray-700 hover:text-gray-500"
          >
            Past Issues
          </Link>
          <Link
            to={year ? `/${year}/print` : '/print'}
            class="text-base font-medium text-gray-700 hover:text-gray-500"
          >
            Request a Printed Copy
          </Link>
          {year !== 2023 && (
            <Link
              to="/2023"
              class="whitespace-nowrap text-base font-medium text-gray-700 hover:text-gray-500"
            >
              Current Issue
            </Link>
          )}
        </div>
      </nav>
    </header>
  )
}
