import { InfographicCard } from './InfographicCard'
import { PhotoInfographicCard } from './PhotoInfographicCard'

export const BondInfographic = () => {
  return (
    <>
      <div class="relative flex min-h-screen w-full flex-col justify-center py-6 sm:py-12">
        <div class="relative -mx-8 flex w-[calc(100%+theme(spacing.16))] flex-col items-center overflow-hidden bg-gray-50 pb-0 shadow-inner @container sm:mx-0 sm:w-full sm:rounded-3xl lg:-mx-10 lg:w-[calc(100%+theme(spacing.20))]">
          <div>
            <div class="w-full px-8 pt-16 text-center @3xl:col-start-2 @3xl:row-start-1">
              <h2 class="text-sm font-medium text-sv-crimson">2024 Bond</h2>
              <p class="text-balance mx-auto mt-4 max-w-md font-display text-3xl font-semibold tracking-[-0.015em] text-gray-900">
                <span>
                  Significant investment with no increase in property taxes.
                </span>
              </p>
              <p class="mx-auto mb-6 mt-4 max-w-xl text-base text-gray-500">
                St. Vrain Valley School District's 2024 bond initiative{' '}
                <span class="whitespace-nowrap rounded bg-sv-crimson px-1 py-0.5 font-semibold text-gray-50">
                  will not
                </span>{' '}
                increase property taxes¹, and will represent a significant
                investment in advancing the future of our community. Passage of
                the bond will:
              </p>
            </div>
            <div class="relative grid gap-4 p-8 @lg:grid-cols-2 @3xl:grid-cols-3">
              <PhotoInfographicCard
                title="Empower the Future of Our Nation"
                description="Provide the education that today's students will need for tomorrow's jobs and careers, including skilled trades (plumbing, electrical, and construction), computer systems, cybersecurity, healthcare, and more"
                imgSrc="/wp-content/themes/magazine/graduation.png"
                fullHeight
              />
              <PhotoInfographicCard
                title="Improve Safety and Security"
                description="Improve safety and security in school buildings to foster an environment of learning"
                imgSrc="/wp-content/themes/magazine/safety-4.png"
              />
              <PhotoInfographicCard
                title="Perform Critical Building Maintenance"
                description="Perform critical building maintenance and replace outdated electrical, plumbing, and HVAC systems"
                imgSrc="/wp-content/themes/magazine/maintenance-2.png"
              />
              <PhotoInfographicCard
                title="Continue Our Commitment to Excellence"
                description="Continue the district's commitment to academic excellence, safety, and accountability"
                imgSrc="/wp-content/themes/magazine/sky.png"
              />
              <PhotoInfographicCard
                title="Classroom and New School Construction"
                description="Provide classroom additions and construction of new school buildings to address overcrowding and future enrollment"
                imgSrc="/wp-content/themes/magazine/construction.png"
              />
            </div>
          </div>
          <div className="h-px w-full bg-gradient-to-r from-gray-50 via-gray-200 to-gray-50"></div>
          {/* <div className="bg-gradient-to-b from-sv-light-blue/20 to-sv-light-blue/5"> */}
          <div>
            <div class="mt-24 w-full px-8 text-center @3xl:col-start-2 @3xl:row-start-1 @3xl:mt-12">
              <h2 class="text-sm font-medium text-sv-navy">
                Empower the future
              </h2>
              <p class="text-balance mx-auto mt-4 max-w-md font-display text-3xl font-semibold tracking-[-0.015em] text-gray-900">
                <span>How is this possible?</span>
              </p>
              <p class="mx-auto mb-6 mt-4 max-w-xl text-base text-gray-500">
                Here is how passing of the bond will not increase property
                taxes¹ for community members:
              </p>
            </div>
            <div class="relative grid items-start gap-4 p-8 @3xl:grid-cols-3">
              <InfographicCard
                title="Community Investment"
                description="St. Vrain voters pass bonds that are paid through property taxes. Bonds are used to make investments to increase school safety, complete critical building maintenance, add classroom and instructional space, build schools, and complete other bricks and mortar infrastructure projects."
                imgSrc="/wp-content/themes/magazine/community.svg"
              />
              <InfographicCard
                title="Strong Financial Management"
                description="Through strong financial management, budget outperformance, debt restructuring, and early debt payoff, the district has saved taxpayers more than $82 million over the past two decades, including $36.8 million this past December. This district also decreased the property tax rate for 2023."
                imgSrc="/wp-content/themes/magazine/financial.svg"
              />
              <InfographicCard
                title="Population and Economic Growth"
                description="The St. Vrain community continues to experience growth in both population and economic strength, increasing the number of resources available to invest in our public schools."
                imgSrc="/wp-content/themes/magazine/growth.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <style></style>
    </>
  )
}
