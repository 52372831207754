import './App.css'
import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Home } from './pages/Home'
import { Story } from './pages/Story'
import { About } from './pages/About'
import { Print } from './pages/Print'
import { StaticPage } from './pages/StaticPage'
import { Archive } from './pages/Archive'
import { StoryKloster } from './pages/StoryKloster'

const devYears = [2025]
const dynamicYears = [2020, 2022, 2023, 2024, 2025]
const staticYears = [2016, 2017, 2018, 2019]
const activeYear = 2024
const videoStories = [
  '/2023/compare',
  '2024/championships-are-won-in-the-preparation',
]

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {staticYears.map((year) => (
          <>
            <Route path={`/${year}`} element={<StaticPage year={year} />} />
            <Route
              path={`/${year}/:slug`}
              element={<StaticPage year={year} />}
            />
          </>
        ))}
        {dynamicYears.map((year) => (
          <>
            <Route path={`/${year}`} element={<Home year={year} />} />
            <Route path={`/${year}/about`} element={<About year={year} />} />
            <Route path={`/${year}/print`} element={<Print year={year} />} />
            <Route path={`/${year}/:slug`} element={<Story year={year} />} />
          </>
        ))}
        {devYears.map((year) => (
          <>
            <Route path={`/${year}-dev`} element={<Home year={year} />} />
            <Route
              path={`/${year}-dev/about`}
              element={<About year={year} />}
            />
            <Route
              path={`/${year}-dev/print`}
              element={<Print year={year} />}
            />
            <Route
              path={`/${year}-dev/:slug`}
              element={<Story year={year} />}
            />
            <Route path={`/${year}/:slug`} element={<Story year={year} />} />
          </>
        ))}
        {/* {videoStories.map((path) => (
          <Route path={path} element={<StoryKloster year={path.split('/')[1]} />} />
        ))} */}
        <Route path="/" element={<Navigate to={`/${activeYear}`} replace />} />
        <Route path="/about" element={<About year={activeYear} />} />
        <Route path="/archive" element={<Archive year={activeYear} />} />
        <Route path="/print" element={<Print year={activeYear} />} />
        <Route path="*" element={<Navigate to={`/${activeYear}`} replace />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
